import { useEffect, useState, Suspense, lazy } from 'react';
import './App.css';
import { Header } from './components/header/Header';
import { Banner } from './components/banner/Banner';
import { Footer } from './components/footer/Footer';
import data from './API/rms.json';

const Section = lazy(() => import('./components/section/Section.jsx').then(module => ({ default: module.Section })));
const Emblems = lazy(() => import('./components/emblems/Emblems.jsx').then(module => ({ default: module.Emblems })));
const Marks = lazy(() => import('./components/marks/Marks.jsx').then(module => ({ default: module.Marks })));
const Testimonials = lazy(() => import('./components/testimonial/Testimonials.jsx').then(module => ({ default: module.Testimonials })));
const AboutUs = lazy(() => import('./components/about/AboutUs.jsx').then(module => ({ default: module.AboutUs })));
const Modal = lazy(() => import('./components/modal/Modal.jsx').then(module => ({ default: module.Modal })));

function App() {
  const [currentRoom, setCurrentRoom] = useState(null);
  const [roomsList, setRoomsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = () => {
    setIsLoading(true);
    const roomsData = data["accom"];

    if (roomsData) {
      setRoomsList(roomsData);
      setIsLoading(false);
    }
  };

  const toSetCurrentRoom = (id) => {
    setCurrentRoom(id);
  }

  return (
    <div className="App">
      <Header />
      <Banner />
      <div className={`fade-in ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
        <Suspense fallback={<div>Loading...</div>}>
          <Section list={roomsList} setCurrentRoom={toSetCurrentRoom} />
          <Emblems />
          <Marks />
          <Testimonials />
          <AboutUs />
        </Suspense>
      </div>
      <Footer />
      {currentRoom && (
        <Suspense fallback={<div>Loading modal...</div>}>
          <Modal currentRoom={currentRoom} setCurrentRoom={toSetCurrentRoom} />
        </Suspense>
      )}
    </div>
  );
}

export default App;
