import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import banner1 from '../../imgs/banner/IMG_1090 1.jpg';
import banner2 from '../../imgs/banner/IMG_1352 1.jpg';
import banner3 from '../../imgs/banner/IMG_1352 2.jpg';
import banner4 from '../../imgs/banner/IMG_1622 2.jpg';
import { QRModal } from '../qrModal/QRModal';

export const Banner = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleBookingClick = (e) => {
    if (!isMobile) {
      e.preventDefault();
      setIsQRModalOpen(true);
    }
  };

  return (
    <div>
      <div className="img__banner">
        <img src={banner1} alt="" className="img__banner-slice" />
        <img src={banner3} alt="" className="img__banner-slice" />
        <img src={banner2} alt="" className="img__banner-slice" />
        <img src={banner4} alt="" className="img__banner-slice" />
      </div>

      <span className='img__span'>
        {t('main')}
      </span>

      <a href="tel:+380669886515" onClick={handleBookingClick}>
        <button
          className='img__button'
        >
          {t('footerBooking')}
        </button>
      </a>

      <QRModal 
        isOpen={isQRModalOpen} 
        onClose={() => setIsQRModalOpen(false)} 
      />
    </div>
  )
}

