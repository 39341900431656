import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationUA from './locales/ua/translation.json';
import translationEN from './locales/en/translation.json';

i18next
  .use(initReactI18next)
  .init({
    lng: 'ua',
    fallbackLng: 'ua',
    resources: {
      ua: {
        translation: translationUA
      },
      en: {
        translation: translationEN
      }
    }
  });

export default i18next;
