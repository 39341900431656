import { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import './QRModal.css';

export const QRModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => setIsContentVisible(true), 100);
    } else {
      setIsContentVisible(false);
      setTimeout(() => setIsVisible(false), 300);
    }
  }, [isOpen]);

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('qr-modal--container')) {
      onClose();
    }
  };

  if (!isVisible) return null;

  return (
    <div 
      className={`qr-modal--container ${isVisible ? 'active--modal' : ''}`}
      onClick={handleOverlayClick}
      role="dialog"
      aria-modal="true"
      aria-labelledby="qr-modal-title"
    >
      <div className={`qr-modal--content ${isContentVisible ? 'content-visible' : ''}`}>
        <button 
          onClick={onClose} 
          className='qr-modal--cross'
          aria-label={t('close')}
        >
          <span aria-hidden="true">×</span>
        </button>

        <div className="qr-modal__content">
          <h2 id="qr-modal-title" className="qr-modal__title">
            {t('scanToCall')}
          </h2>
          
          <div className="qr-modal__qr-container">
            <QRCodeCanvas 
              value="tel:+380669886515" 
              size={200}
              level="H"
              className="qr-modal__qr-code"
            />
          </div>

          <p className="qr-modal__description">
            {t('scanQRDescription')}
          </p>

          <span className="qr-modal__span--number">
            +380 669 886 515
          </span>
        </div>
      </div>
    </div>
  );
}; 